<template>
	<v-layout column fill-height>
		<v-layout v-if="pageLoading" align-center fill-height justify-center>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>
		<NoWorkspaceAvailable v-else-if="!hasWorkspaces">
			<w-layout align-center column fill-height justify-center>
				<w-flex v-t="'holding.customers.no_workspace_available_title'" display-1 mb-4 shrink />
				<w-flex v-if="!hasWorkspaceModule" v-t="'holding.customers.no_workspace_available_text_without_workspace_module'" mb-2 shrink subheading />
				<template v-else>
					<w-flex v-t="'holding.customers.no_workspace_available_text_with_workspace_module'" mb-2 shrink subheading />
					<w-btn @click="goTo('workspaces')">{{ $t('actions.click_here') }}</w-btn>
				</template>
			</w-layout>
		</NoWorkspaceAvailable>
		<template v-else>
			<v-flex shrink>
				<v-toolbar dense>
					<w-search-input v-model="filters.search" :placeholder="$t('holding.customers.search_for_customer')" />
					<w-btn flat icon="fas fa-filter" mini small @click="showFilterMenu">{{ $t('actions.filter') }}</w-btn>
					<v-menu
						v-model="displayFilterMenu"
						absolute
						:close-on-content-click="false"
						offset-y
						:position-x="filterMenuCoordinates.x"
						:position-y="filterMenuCoordinates.y"
					>
						<v-list>
							<v-list-tile>
								<w-list-tile-content style="min-height: 60px">
									<w-switch v-model="filters.with_trashed" :label="$t('holding.customers.display_deleted_customers')" :ripple="false" />
								</w-list-tile-content>
							</v-list-tile>
							<v-list-tile>
								<w-list-tile-content style="min-height: 60px">
									<WorkspaceFilter v-model="filters.accounting_firm_id" :items="workspaces" />
								</w-list-tile-content>
							</v-list-tile>
						</v-list>
					</v-menu>
					<w-btn v-if="$vuetify.breakpoint.mdAndUp" flat :icon="extendTable ? 'zoom_out' : 'zoom_in'" mini @click="extendTable = !extendTable">
						{{ extendTable ? $t('simplified_view') : $t('detailed_view') }}
					</w-btn>
					<v-spacer />
					<v-divider vertical />
					<w-btn icon="add" :mini="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="displayCustomerCreationDialog = true">{{
						$t('holding.customers.create_a_customer')
					}}</w-btn>
				</v-toolbar>
			</v-flex>
			<v-flex scroll-y>
				<v-layout justify-center row>
					<v-flex mx-4>
						<CustomersTable
							display-owner
							:extended="extendTable"
							:loading="vendorsLoading"
							:no-data-text="noDataText"
							:pagination="pagination"
							:search="filters.search"
							:total-items="pagination.totalItems"
							:value="vendors"
							@click-on-line="onClickOnLine($event)"
							@update:pagination="onPaginationUpdated($event)"
						/>
					</v-flex>
				</v-layout>
			</v-flex>
			<CustomerCreationDialog v-model="displayCustomerCreationDialog" :service="service" @customer-created="onCustomerCreated($event)" />
		</template>
		<w-dialog
			v-model="displayRedirectionDialog"
			max-width="500px"
			:title="$t('customers.go_to_client', { name: vendorToRedirectTo ? vendorToRedirectTo.company : null })"
			warning
		>
			<v-layout column>
				<v-flex>{{
					$t(
						'customers.about_to_be_redirected',
						vendorToRedirectTo ? { accounting_firm: vendorToRedirectTo.accounting_firm.name, vendor: vendorToRedirectTo.company } : {}
					)
				}}</v-flex>
				<v-checkbox v-model="doNotDisplayRedirectionDialog" color="primary" :label="$t('actions.do_not_display_this_message')" />
			</v-layout>
			<template v-slot:actions>
				<v-layout row justify-end>
					<w-btn flat @click="redirectToVendor(vendorToRedirectTo)">{{ $t('continue') }}</w-btn>
				</v-layout>
			</template>
		</w-dialog>
	</v-layout>
</template>

<script>
import CustomersModuleGuard from '@/mixins/ModulesGuards/Holding/CustomersModuleGuard'

const REDIRECTION_DIALOG_KEY = 'holding-customers.do-not-show-redirection-dialog'

export default {
	name: 'HoldingCustomersManager',
	components: {
		CustomerCreationDialog: () => ({
			component: import('@/components/CustomersManager/CustomerCreationDialog')
		}),
		CustomersTable: () => ({
			component: import('@/components/CustomersManager/CustomersTable')
		}),
		NoWorkspaceAvailable: () => ({
			component: import('@/components/Holding/NoWorkspaceAvailable')
		}),
		WorkspaceFilter: () => ({
			component: import('@/components/Holding/Filters/WorkspaceFilter')
		})
	},
	mixins: [CustomersModuleGuard],
	inject: ['holdingContext'],
	data: function () {
		return {
			displayCustomerCreationDialog: false,
			displayFilterMenu: false,
			displayRedirectionDialog: false,
			doNotDisplayRedirectionDialog: false,
			extendTable: false,
			filters: {
				accounting_firm_id: [],
				search: '',
				with_trashed: false
			},
			filterMenuCoordinates: {
				x: 0,
				y: 0
			},
			hasWorkspaces: false,
			headers: [
				{
					sortable: true,
					text: this.$tc('holding.customers.customer', 1),
					value: 'company'
				}
			],
			pageLoading: false,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 10,
				sortBy: 'company',
				totalItems: 0
			},
			searchTimeout: null,
			vendors: [],
			vendorsLoading: false,
			vendorToRedirectTo: null,
			workspaces: []
		}
	},
	computed: {
		hasWorkspaceModule: function () {
			return this.service.hasModule('workspaces')
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		noDataText: function () {
			let result = null
			if (this.vendorsLoading) {
				result = this.$t('customers.loading-progress')
			} else if (Array.isArray(this.filters.accounting_firm_id) && this.filters.accounting_firm_id.length == 0) {
				result = this.$t('customers.no-accounting-firm-id-selected')
			} else if (this.filters.search) {
				result = this.$t('customers.no-data-found-for-search', { search: this.filters.search })
			} else if (!this.filters.with_trashed) {
				result = this.$t('customers.no-active-data-found')
			} else {
				result = this.$t('customers.no-data-found')
			}
			return result
		}
	},
	watch: {
		doNotDisplayRedirectionDialog: {
			handler: 'changeDoNotDisplayRedirectionDialog'
		},
		'filters.accounting_firm_id': {
			handler: function (oldVal, newVal) {
				if (
					!Array.isArray(oldVal) ||
					!Array.isArray(newVal) ||
					!(oldVal.length == 0 && newVal.length == this.workspaces.length) ||
					!(newVal.length == oldVal.length && newVal.length == this.workspaces.length)
				) {
					this.onSearchModified()
				}
			}
		},
		'filters.search': {
			handler: 'onSearchModified'
		},
		'filters.with_trashed': {
			handler: function (with_trashed) {
				this.loadVendors({ accounting_firm_id: this.filters.accounting_firm_id, search: this.filters.search, with_trashed })
			}
		},
		holdingId: {
			handler: 'loadPageData'
		}
	},
	mounted: function () {
		if (this.holdingId) {
			this.loadPageData()
		}
	},
	methods: {
		changeDoNotDisplayRedirectionDialog: function (value) {
			if (value) {
				localStorage.setItem(REDIRECTION_DIALOG_KEY, true)
			} else {
				localStorage.removeItem(REDIRECTION_DIALOG_KEY)
			}
		},
		getSortingAlgorithm: function () {
			if (!this.pagination.sortBy) {
				return null
			}

			return (this.pagination.descending ? '-' : '+') + this.pagination.sortBy
		},
		goTo: function (routeName) {
			this.appService.goTo(routeName)
		},
		loadPageData: function () {
			this.pageLoading = true
			this.service
				.listAccountingFirms(this.holdingId)
				.then(accountingFirms => {
					this.hasWorkspaces = accountingFirms.length > 0
					this.workspaces = accountingFirms
				})
				.finally(() => {
					this.pageLoading = false
				})
				.then(() => {
					return this.loadVendors()
				})
		},
		loadVendors: function (filters = {}) {
			this.vendorsLoading = true
			if (!filters.hasOwnProperty('page')) {
				filters.page = 1
			}
			if (!filters.hasOwnProperty('rowsPerPage')) {
				filters.rowsPerPage = this.pagination.rowsPerPage
			}
			if (filters.hasOwnProperty('accounting_firm_id') && !filters.accounting_firm_id) {
				delete filters.accounting_firm_id
			}
			if (filters.hasOwnProperty('search') && !filters.search) {
				delete filters.search
			}
			if (filters.with_trashed === false) {
				delete filters.with_trashed
			}
			const sortingAlgorithm = this.getSortingAlgorithm()
			if (sortingAlgorithm) {
				filters.sort = sortingAlgorithm
			}

			return this.service
				.listVendors(this.holdingId, filters)
				.then(response => {
					if (response.hasOwnProperty('pagination')) {
						const pagination = response.pagination
						const vendors = response.data
						this.pagination.page = pagination.currentPage
						this.pagination.rowsPerPage = pagination.perPage
						this.pagination.totalItems = pagination.total
						this.vendors = vendors
					} else {
						this.pagination.page = 1
						this.pagination.rowsPerPage = -1
						this.pagination.totalItems = response.length
						this.vendors = response
					}
				})
				.finally(() => {
					this.vendorsLoading = false
				})
		},
		onClickOnLine: function (vendor) {
			this.service.hasAccessToVendor(this.holdingId, vendor.id, this.userId).then(hasAccess => {
				if (!hasAccess) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('customers.errors.no_access_to_vendor', { name: vendor.company }))
				} else if (localStorage.getItem(REDIRECTION_DIALOG_KEY) == null) {
					this.vendorToRedirectTo = vendor
					this.displayRedirectionDialog = true
				} else {
					this.redirectToVendor(vendor)
				}
			})
		},
		onCustomerCreated: function () {
			this.loadVendors({
				accounting_firm_id: this.filters.accounting_firm_id,
				page: this.pagination.page,
				rowsPerPage: this.pagination.rowsPerPage,
				search: this.filters.search,
				with_trashed: this.filters.with_trashed
			})
		},
		onPaginationUpdated: function (paginationData) {
			Object.assign(this.pagination, paginationData)
			this.loadVendors({
				accounting_firm_id: this.filters.accounting_firm_id,
				page: this.pagination.page,
				rowsPerPage: this.pagination.rowsPerPage,
				search: this.filters.search,
				with_trashed: this.filters.with_trashed
			})
		},
		onSearchModified: function () {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = null
			}

			if (Array.isArray(this.filters.accounting_firm_id) && this.filters.accounting_firm_id.length == 0) {
				this.pagination.page = 1
				this.pagination.totalItems = 0
				this.vendors = []
				return
			}

			this.searchTimeout = setTimeout(
				function () {
					this.loadVendors({
						accounting_firm_id: this.filters.accounting_firm_id,
						search: this.filters.search,
						with_trashed: this.filters.with_trashed
					})
				}.bind(this),
				1000
			)
		},
		redirectToVendor: function (vendor) {
			const redirectTo = this.$router.resolve({
				name: 'customer',
				params: {
					context: this.$router.encryptContext(vendor.accounting_firm.id, vendor.id)
				}
			})
			const result = `https://${vendor.domain}/autologin?a_id=${vendor.accounting_firm.id}&token=${this.$store.state.auth.token}&redirect=${encodeURIComponent(
				redirectTo.href
			)}`
			window.location.href = result
		},
		showFilterMenu: function (clickEvent) {
			this.filterMenuCoordinates.x = clickEvent.clientX
			this.filterMenuCoordinates.y = clickEvent.clientY
			this.displayFilterMenu = true
		}
	}
}
</script>